<template>
  <div id="x-spreadsheet-demo"></div>
</template>

<script>
import Spreadsheet from "x-data-spreadsheet";
const xmlData = require("./data/1.json");
export default {
  name: "tmp-sudidi-com",
  components: {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const s = new Spreadsheet("#x-spreadsheet-demo")
        .loadData(xmlData) // load data
        .change((data) => {
          // save data to db
          console.log("XXx", JSON.stringify(data));
        });

      // data validation
      s.validate();
    },
  },
};
</script>

<style></style>
